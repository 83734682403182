import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Translate } from "react-localize-redux";
import Download from "../components/download";

class CTA extends Component {
  
  render() {  
    return (
      <div className="cta u-text-center">
        <div className="cta-content">
          <Fade>
            <Container>
              <Row>
                <Col>
                  <h3 className="cta-heading"><Translate id="title.download-the-app" /></h3>
                  <Download/>
                </Col>
              </Row>
            </Container>
          </Fade>
        </div>
      </div>
    );
  }
}

export default CTA;
