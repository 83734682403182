import React, { Component } from "react";
import axios from "axios";
import { Translate } from "react-localize-redux";
import { Row, Col, Form } from "react-bootstrap";
import Login from "./login";
import Loader from "../components/loader";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

/* global Stripe */
const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const successURL = process.env.REACT_APP_SUCCESS_URL;
const cancelURL = process.env.REACT_APP_FAILED_URL;

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: 6, // This is TG merchant Id
      plans: [],
      planId: "",
      errorMessage: "",
      isLoading: false,
      isLoadingStripe: false,
      sessionId: "",
      selectedCurrency: "usa",
      selectedPaymentMethod: "Card",
      subscription: "",
      subscriptionSourceType: ""
    }
    this._isMounted = false;
  }

  componentDidMount() {
    this.auth = this.props.auth;

    if (this.auth.isAuthenticated()) {
      this.loadPackages();
      this.loadProfile(); 

    }
    
    let selected = window.sessionStorage.getItem("selectedCurrency");
    this.setState({ selectedCurrency: selected });
    

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeCurrency(e) {
    this.setState({ selectedCurrency: e.target.value });
    window.sessionStorage.setItem("selectedCurrency", e.target.value);
  }

  loadProfile = () => {
    axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile`, { 
      headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
      }
    )
    .then(resp => {
      this.setState({
        subscription: resp.data.Subscriptions[0],
        subscriptionSourceType : resp.data.Subscriptions[0] ? resp.data.Subscriptions[0].Source.SourceType : undefined
      })
    })
  }

  // Retrieve TG plans for a specific merchant
  loadPackages = () => {
    axios.get(`${base_services_api}/merchants`, { headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }})
      .then(resp => {
        
        var merchants = resp.data;
  
        if(merchants.some(merchant => merchant.Name === "Stripe") && this.props.auth.authData.access_token !== null) {

          axios.get(`${base_services_api}/packages/allplans`, { 
            headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }, params: { 'merchantId': `${this.state.merchantId}` }}
          )
          .then(resp => {
            this.setState({ 
              plans: resp.data,
              isLoading: true
            })
          })
          .catch(err => {
            this.setState({ 
              isLoading: false
            })
          })
        } else {
          this.setState({ 
            isLoading: false, 
            errorMessage: "Sorry, there are no plans available. Please contact us." 
          })
        }
      
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
        this.props.auth.logout();
      })
      
  }

  // Create Stripe Session
  stripeSession = (plan) => {

    this.setState({ isLoadingStripe: true })
    
    this.setState({planId: plan.MerchantCode}, function () {
      axios.post(`${base_services_api}/subscription/stripesession`, 
      { 
        'PaymentMethod': `${this.state.selectedPaymentMethod }`,
        'SuccessUrl': `${successURL}`, 
        'CancelUrl': `${cancelURL}`,
        'PlanCode':  `${plan.MerchantCode}`
      },
      { 
        headers: { 
          'StoreId': storeId, 
          'Authorization': `Bearer ${this.props.auth.authData.access_token}` 
        }, 
        
      })
      .then(resp => {
        this.setState({ sessionId: resp.data })
        localStorage.setItem("sessionId", resp.data);
        this.redirectToCheckout();

      })
      .catch(err => {
        this.setState({
          message: err
        })
      })
    });

  };


  redirectToCheckout() {
    try {
      const { checkout } = stripe.redirectToCheckout({
        sessionId: `${this.state.sessionId}`
      })
      return checkout;
    } catch(error) {
      this.state({ message: error.message });
    }
  }
  
  render() {
    const { isLoading } = this.state;
    const auth = this.props.auth;
    let plans;
    let currencySelector;
    let loginBtn;
    let finePrint;
    
    let usdPlans = this.state.plans.filter(plan => {
      return plan.Currency === "USD";
    }); 

    if (!auth.isAuthenticated()) {
        
      loginBtn = <Login auth={this.props.auth} btnClass="nav-btn" />

      plans = <Col sm="12" className="text-center">
        <p><Translate id="content.login-or-register" /></p>
        {loginBtn}
      </Col>

    } else {

      currencySelector = <Row>
        <Col>
          <p className="text-center"><Translate id="content.preferred-currency" /></p>
          <Form.Group>
            <div className="select-wrapper">
              <Form.Control 
                as="select"
                onChange={this.changeCurrency.bind(this)}
                value={this.state.selectedCurrency == null ? '' : this.state.selectedCurrency}
                className="country-selector"
              >
                <option value="usd">USD</option>
              </Form.Control>
            </div>
          </Form.Group>
        </Col>
      </Row>
    
      plans = usdPlans.map((plan) => {
        
        return <Col sm="6" key={plan.MerchantCode} id="usd">
          <div className="premium-package"> 
            <div className="package-name">
              ${plan.Cost} ({plan.Currency})<br/>
              <small>{plan.Name[0].Value}</small>
            </div>

            {this.state.subscription ? 
              <Translate id="content.active-subscription" /> 
              : 
              this.state.isLoadingStripe ? 
                <Loader/> :   
                <div className="package-button"><button className="btn-buy" id={plan.MerchantCode} onClick={this.stripeSession.bind(this, plan)}>
                <Translate id="button.get-started" />
                  </button></div>  
              }
          </div>
        </Col>
      })

      finePrint = <Col sm="12">
        <p>
          <small>
            {(this.state.subscriptionSourceType === "Apple IAP" && <Translate id="subscription.apple" />) || (this.state.subscriptionSourceType === "Google Play" && <Translate id="subscription.google" />)}
          </small>
        </p>
      </Col>
    }

    if (!isLoading && auth.isAuthenticated()) {
      return <Col sm="12" className="text-center">
        <div className="container">
          <Loader/>
        </div>
        <p><Translate id="content.loading-plans" /></p>
      </Col>;
    } else {
      return (
       
        <Col sm="12">
          {currencySelector}
          
          <Row className="text-center">
            {plans}
          </Row>

          <Row className="text-center">
            {finePrint}
          </Row>

          { 
            this.state.errorMessage !== '' &&
              <Col sm="12" className="text-center">
                {this.state.errorMessage}
              </Col>
          }
        </Col>
      )
    }
  }
}

export default Plans;