import React, { Component } from "react";
import axios from "axios";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import Plans from "../components/plans";
import { Container, Row, Col } from "react-bootstrap";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class Premium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 'default',
      subscription: ""
    }
  }

  componentDidMount() {
    this.auth = this.props.auth;  
    this.checkSubscription();
  }

  checkSubscription() {
    if (this.auth.isAuthenticated()) {
      axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile`, { 
        headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
        }
      )
      .then(resp => {
        this.setState({
          subscription: resp.data.Subscriptions[0]
        })

        if (this.state.subscription) {
          window.location.href = "/";
          this.setState({ isLoading: true })
        }
      })
    } 
  }

  render() {
   
    const auth = this.props.auth;

    var paymentOptions;

    paymentOptions =
      <section className="premium-payment" id="premium-payment">
        <Container>
          <Row id="creditcard">
            <Col id="creditcard" sm="12" md="12" lg={{ span: 10, offset: 1 }}>
            <div className="section-heading text-center"><Translate id="content.buy-premium" /></div>
              <Row>
                <Plans auth={auth}/>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

    return (
      <div className="page-content" id="content">
        
        <section className="section-hero">
          <Container className="justify-content-center align-self-center text-center">
            <Row>
              <Col sm="12" className="hero-details">
                <h1 className="hero-heading"><Translate id="title.premium" /><br/></h1>
                <p><Translate id="content.premium" /></p>
              </Col>
            </Row>
          </Container>
        </section>

        { paymentOptions }
  
      </div>
    );
  }
}

export default withLocalize(Premium);
