import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";

import Auth from "./utils/auth";

import Home from "./containers/home";
import Premium from "./containers/premium";
import Account from "./containers/account";
import Error from "./containers/error";
import PhoneAuth from "./components/firebase-phoneauth";
import Success from "./containers/success";
import Failed from "./containers/failed";
import AcceptInvite from "./containers/accept-invite";
import ProfileEdit from "./containers/profile-edit";
import Header from "./components/header";
import Footer from "./components/footer";

import ScrollToTop from "react-router-scroll-top";


const auth = new Auth();
auth.loadAuth();

class App extends Component {

  render() {
    return (
      <LocalizeProvider>
        <Fragment>
          <Header auth={auth} /> 
          <main> 
            <ScrollToTop>
              <Switch>
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/premium" render={()=> <Premium auth={auth} />} />
                <Route exact={true} path="/invite" render={()=> <AcceptInvite auth={auth} />} />
                <Route exact={true} path="/profile" render={()=> <Account auth={auth} />} />
                <Route exact={true} path="/profile-edit" render={()=> <ProfileEdit auth={auth} />} />
                <Route exact={true} path="/success" render={()=> <Success auth={auth} />} />
                <Route exact={true} path="/failed" render={()=> <Failed />} />
                <Route exact={true} path="/phoneauth" component={PhoneAuth} />
                <Route status={404} component={Error} />
              </Switch>
            </ScrollToTop>
          </main>
          <Footer/>
        </Fragment> 
      </LocalizeProvider>
    );
  }

}

export default App;
