import React, { Component, Fragment } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Translate } from "react-localize-redux";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class MobileNav extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      subscription: false,
      menuOpen: false
    }
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }

  closeMenu() {
    this.setState({menuOpen: false})
  }

  toggleMenu() {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }

  getYear() {
    return new Date().getFullYear();
  }

  componentDidMount() {
    this.auth = this.props.auth;

    console.log(this.auth);
    
    if (this.auth.isAuthenticated()) {
      axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile`, { 
        headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
        }
      )
      .then(resp => {
        if (resp.data.Subscriptions[0]) {
          this.setState({ 
            subscription: true
          });
        }
      })
    }
  }

  render() {
    const { subscription } = this.state;

    return (
      <nav className="nav-mobile" id="navigation">
        <Menu
          right 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <ul>
            <Fragment>
              <li><Link to="/"><Translate id="nav.home" /></Link></li>
              {!subscription && (
                   <li><Link to="/premium" onClick={() => this.closeMenu()}><Translate id="nav.premium" /></Link></li> 
              )}
            </Fragment>
          </ul>
          
          <div className="nav-footer">
            <div className="footer-copyright">
              &copy; {process.env.REACT_APP_NAME} {this.getYear()}
            </div>
          </div>
        </Menu>
      </nav>
    )
  }
}

export default withRouter(MobileNav);