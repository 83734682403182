import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class Footer extends Component {

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <footer>
        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="footer-copyright">
                &copy; {process.env.REACT_APP_NAME} {this.getYear()}
              </div>
            </Col>
          </Row>
        </Container>

      </footer>
    );
  }
}

export default Footer;
