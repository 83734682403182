import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { Translate } from "react-localize-redux";
import Loader from "../components/loader";

class CodeValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation_code: '',
      message: '',
      cssclass: 'success',
      isLoading: false
    }
  }

  onChange = (e) => {
    const val = e.target.value;
    for(let i in val) {
      if(/[a-zA-Z0-9]/.test(val[i]) === false)  
        return;
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  }
  
  onValidateClick = () => {

    this.setState({ isLoading: true })
    axios.post(`${process.env.REACT_APP_SERVICES_API_BASE}/users/redeemvoucher?code=${this.state.validation_code}`, null, { headers: { 'StoreId': `${process.env.REACT_APP_STOREID}`, 'Authorization': `Bearer ${this.props.auth.authData.access_token}`}})
      .then(resp => {
        this.props.onValidate();
        this.setState({ 
          message: <Translate id="content.redeem-voucher" />, 
          cssclass: 'status-success'
        })
      })
      .catch(err => this.setState({ 
        message: <Translate id="content.voucher-not-valid" />, 
        cssclass: 'status-error',
        isLoading: false
      }))
  }

  render() {
    return(
      <div className="justify-content-center align-self-center voucher">

        <h3 className="u-no-margin u-no-margin-bottom"><Translate id="title.redeem-voucher" /></h3>
        <p className="u-no-margin-top"><Translate id="content.redeem-voucher" /></p>

        <Row>
          <Col xs="12">
            <div className={this.state.cssclass}>
              {this.state.message}
            </div>
          </Col>

          <Col sm="12" md="8" lg="8">
            <Translate>
              {({ translate }) => 
              <input type="text" className="redeem-input" name="validation_code" value={this.state.validation_code} onChange={this.onChange} placeholder={translate("content.enter-code")} maxLength="12"/>}
            </Translate>
          </Col>

          <Col sm="12" md="4" lg="4">
            {this.state.isLoading ? 
              <Loader/> : <button className="btn-primary btn-validate" onClick={this.onValidateClick}><Translate id="button.validate" /></button> }
          </Col>

        </Row>
      </div>
    )
  }
}

export default CodeValidation;
