import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { Container, Row, Col } from "react-bootstrap";
import Hero from "../components/hero";
import CTA from "../components/cta";

class Home extends Component {

  render() {
    
    return (
      <div className="page-content" id="content">
        <Hero/>

        <section className="section-trending" id="trending">
          <Container>
              <Row>
                <Col sm="12" md="5" lg="5" className="justify-content-center align-self-center">
                  <h2 className="section-intro"><Translate id="title.about" /></h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras in sapien at lacus scelerisque fermentum. Proin nisl orci, consectetur a purus sit amet, porttitor rutrum neque. Quisque lacus nulla, pulvinar sed felis ut, lacinia dictum risus. Quisque eu sem ut urna tempor cursus id et odio. Aenean sapien nunc, consectetur eget pretium et, consectetur id leo. Vivamus lacus nisl, vulputate nec ante et, ultrices tempus mi. Mauris eu metus elit. Donec commodo blandit tortor in posuere. Quisque sodales sodales velit ut suscipit. Mauris dapibus egestas iaculis.</p>
                  <p>Nam sit amet enim eu ligula tincidunt feugiat. Nulla volutpat mauris id aliquet fermentum. Nullam tortor risus, suscipit in pretium sit amet, convallis vitae augue. Curabitur pretium tristique felis, facilisis viverra arcu hendrerit sit amet. Ut dignissim, dui vitae ultricies eleifend, neque nunc scelerisque ligula, nec tincidunt tellus quam a enim. Mauris velit quam, scelerisque sit amet tempus eu, dictum ut orci. Suspendisse vitae risus in risus egestas pretium. Integer tristique arcu mauris, eget consectetur risus ultricies sed.</p>
                </Col>
              </Row>
          </Container>
        </section>

        <CTA/>

      </div>
    );
  }
}

export default withLocalize(Home);
