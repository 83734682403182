import React, { Component, Fragment } from "react";
import { Translate } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import axios from "axios";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class Profile extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      profileName: "",
      profileEmail: "",
      subscriptionSourceType: ""
    }   
  }

  componentDidMount() {
    this.loadProfile();    
  }

  formatDate = date => (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())

  loadProfile = () => {
    axios.get(`${base_services_api}/users/${this.props.auth.sub}/profile`, { 
      headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.access_token}` }
      }
    )
    .then(resp => {
      this.setState({ 
        profileName: resp.data.FirstName,      
        profileEmail: resp.data.PrimaryEmail,
        subscriptionSourceType : resp.data.Subscriptions[0] ? resp.data.Subscriptions[0].Source.SourceType : undefined
      }); 
    })
  }

  onEditClick = () => {
    this.props.history.push("/profile-edit");
  }

  onPremiumClick = () => {
    this.props.history.push("/premium");
  }

  onStripePortalClick = () => {
    window.location = process.env.REACT_APP_CUSTOMER_PORTAL;
  }

  render() {
    const authData = this.props.auth;
    let subscriptions;
    
    if(this.props.subscriptions.length > 0) {
      subscriptions = this.props.subscriptions.map(subscription => (

        <div className="redeem-sidebar" key={subscription.SubscriptionId}>
            <div className="profile-heading"><Translate id="title.profile-details" /> <button className="btn-edit" onClick={this.onEditClick}><Translate id="button.edit" /></button></div>

            <div className="redeem-section">
              <div className="redeem-title"><Translate id="profile.phone-number" /></div>
              <span>{"+" + authData.phone.countryCode + parseInt(authData.phone.number)}</span>
            </div>

            {this.state.profileName ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.name" /></div>
                 <span className="right">{this.state.profileName}</span>
              </div>
            : null }

            {this.state.profileEmail ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.emailaddress" /></div>
                <span>{this.state.profileEmail}</span>
              </div>
            : null}

            <div className="redeem-section">
              <div className="redeem-title"><Translate id="profile.subscription" /></div>
              <Translate>
                {({ translate }) => 
                <span>
                  {new Date(subscription.EndDate) > new Date() ? translate("profile.active") : subscription.IsAutoRenew ? translate("profile.active") : translate("profile.inactive")}
                  {new Date(subscription.EndDate) > new Date() ? " - " + subscription.PackageCost.Name[0].Value : ""}
                </span>}
              </Translate>
            </div>

            {new Date(subscription.EndDate) > new Date() ?
              <Fragment>
                <div className="redeem-section">
                  <div className="redeem-title"><Translate id="profile.start-date" /></div>
                  <span>{this.formatDate(new Date(subscription.StartDate))}</span>
                </div>

                <div className="redeem-section">
                  <div className="redeem-title"><Translate id="profile.end-date" /></div>
                  <span>{this.formatDate(new Date(subscription.EndDate))}</span>
                </div>

                <div className="redeem-section">
                  <div className="redeem-title"><Translate id="profile.auto-renew" /></div>
                  <Translate>
                    {({ translate }) => 
                    <span>
                    {subscription.IsAutoRenew === true ? translate("profile.yes") : translate("profile.no")}</span>}
                  </Translate>

                  <div>
                    <small>
                      {(this.state.subscriptionSourceType === "Apple IAP" && <Translate id="subscription.apple" />) || (this.state.subscriptionSourceType === "Google Play" && <Translate id="subscription.google" />) || (this.state.subscriptionSourceType === "Stripe" && <button className="btn-profile" onClick={this.onStripePortalClick}><Translate id="button.manage" /></button>)}
                    </small>
                  </div>

                </div>                
              </Fragment> : ''}
               
        </div>

      ))
      
    } else {
      subscriptions = <div className="redeem-sidebar">
        <div className="profile-heading"><Translate id="title.profile-details" /> <button className="btn-edit" onClick={this.onEditClick}><Translate id="button.edit" /></button></div>

        <div className="redeem-section">
          <div className="redeem-title"><Translate id="profile.phone-number" /></div>
          <span>{"+" + authData.phone.countryCode + parseInt(authData.phone.number)}</span>
        </div>

        {this.state.profileName ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.name" /></div>
                <span>{this.state.profileName}</span>
              </div>
            : null }

            {this.state.profileEmail ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.emailaddress" /></div>
                <span>{this.state.profileEmail}</span>
              </div>
            : null}

        <div className="redeem-section">
          <div className="redeem-title"><Translate id="profile.subscription" /></div>
          <span>
            <Translate id="profile.inactive" />
            <div><button className="btn-profile" onClick={this.onPremiumClick}><Translate id="button.go-premium" /></button></div>
          </span>
        </div>
      </div>
    }

    return (
      <Fragment>
        {subscriptions}
      </Fragment>
    )
  }
}

//export default Profile;
export default withRouter(Profile);