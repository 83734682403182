import React, { Component, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Profile from "../components/profile";
import Invite from "../components/invite";
import LogoutButton from "../components/logoutbutton";
import CodeValidation from "../components/codevalidation";
import { Translate } from "react-localize-redux";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: []          
    }
    this.loadSubscriptions = this.loadSubscriptions.bind(this);
    this._isMounted = false;       
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadSubscriptions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadSubscriptions() {
    try {
      const subscriptionsData = await this.props.auth.loadSubscriptions();
      this._isMounted && this.setState({ subscriptions: subscriptionsData.data })
    }
    catch (err) {
      this.setState({ subscriptions: [] })
    }
  }  

  refreshSubscriptions = () => {
    this.props.auth.loadSubscriptions();
  }

  handleLogout = () => {
    this.props.auth.logout();
    this.setState({ subscription: false });
    this.props.history.push("/");
  };

  render() {
    const auth = this.props.auth;
    let authProfile;

    if (!auth.isAuthenticated()) {
      authProfile = <Redirect to="/" />
    } else {
      authProfile = <Col className="justify-content-center align-self-center">
        <Row>
          <Col sm="12" md="4">
            <Profile auth={auth.authData} subscriptions={this.state.subscriptions} />
          </Col>
          <Col sm="12" md="8">
            <Invite auth={auth.authData} />   
            <CodeValidation onValidate={this.loadSubscriptions} auth={auth} /> 
          </Col>
        </Row>       
        <Row>
          <Col sm="12" className="btn-logout u-align-right">
            <LogoutButton onLogout={this.handleLogout} />
          </Col>
        </Row>
      </Col>
    }

    return (
      <Fragment>
        <div className="page-content bg-gradient" id="content">
          <div className="redeem-header">
            <Container>
              <Row>
                <Col>
                  <h1><Translate id="title.youraccount" /></h1>
                </Col>
              </Row>
            </Container>
          </div>

          <section className="account">
            <Container>
              <Row>
                {authProfile}
              </Row>
            </Container>
          </section>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Account);
