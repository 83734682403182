import React from "react";
import { Translate } from "react-localize-redux";

const LogoutButton = ({ onLogout }) => {
  const handleClick = () => {
    if (typeof onLogout === "function") {
      onLogout();
    }
  };

  return (
    <button onClick={handleClick} className="btn-secondary">
      <Translate id="button.logout" />
    </button>
  );
};

export default LogoutButton;