import React, { Component, Fragment } from "react";
import { UserAgentProvider, UserAgent } from "@quentin-sommer/react-useragent";
import AppStore from "../assets/img/app-store-badge.png";
import GooglePlay from "../assets/img/google-play-badge.png";

class Download extends Component {
  
  render() {  
    return (
      <Fragment>
        <UserAgentProvider ua={window.navigator.userAgent}>
          <Fragment>
            <UserAgent computer>
              <Fragment>
                <a href={process.env.REACT_APP_DOWNLOAD_IOS} className="cta-badge">
                  <img src={AppStore} alt="Download on the App Store" title="Download on the App Store"/>
                </a>
                <a href={process.env.REACT_APP_DOWNLOAD_ANDROID} className="cta-badge">
                  <img src={GooglePlay} alt="Get it on Google Play" title="Get it on Google Play"/>
                </a>
              </Fragment>
            </UserAgent>
            <UserAgent ios>
              <a href={process.env.REACT_APP_DOWNLOAD_IOS} className="cta-badge">
                <img src={AppStore} alt="Download on the App Store" title="Download on the App Store"/>
              </a>
            </UserAgent>
            <UserAgent android>
              <a href={process.env.REACT_APP_DOWNLOAD_ANDROID} className="cta-badge">
                <img src={GooglePlay} alt="Get it on Google Play" title="Get it on Google Play"/>
              </a>
            </UserAgent>
          </Fragment>
        </UserAgentProvider>
      </Fragment>
    );
  }
}

export default Download;
