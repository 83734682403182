import React, { Component, Fragment } from "react";
import axios from "axios";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import globalTranslations from "../translations/global.json";
import { Container } from "react-bootstrap";
import MobileNav from "../components/nav";
import Login from "../components/login";
import LanguageToggle from "../components/languageselect"; 
import { Translate } from "react-localize-redux";
import logo from "../assets/img/logo.png";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subscription: false
    }

    const languages = [
      { name: "English", code: "en" },
      { name: "Italian", code: "it" }
    ];
    
    const defaultLanguage = window.localStorage.getItem("languageCode") || languages[0].code;
    
    this.props.initialize({
      languages,
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage
      }

    }); 
  }

  componentDidMount() {
    this.auth = this.props.auth;
    
    if (this.auth.isAuthenticated()) {
      axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile`, { 
        headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
        }
      )
      .then(resp => {
        if (resp.data.Subscriptions[0]) {
          this.setState({ 
            subscription: true
          });
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;

    const hasLanguageChanged = prevLangCode !== curLangCode;
    
    if (hasLanguageChanged) {
      window.localStorage.setItem("languageCode", curLangCode);
    }

  }
  
  render() {
    const { subscription } = this.state;
    
    return ( 
      <header>
        <div className="language">
          <div className="container">
            <LanguageToggle />
          </div>
        </div>

        <Container className="header">
          <a href="/" className="logo"><img src={logo} alt="Website Name" /></a>
          <nav className="nav-desktop">
            <ul>
              <Fragment>
                <li><Link to="/"><Translate id="nav.home" /></Link></li>
                {!subscription && (
                  <li>
                    <Link to="/premium">
                      <Translate id="nav.premium" />
                    </Link>
                  </li>
                )}
              </Fragment> 
            </ul>
          </nav>

          <div className="nav-group"> 
            <Login auth={this.props.auth}btnClass="nav-btn" />
          </div>
              
          <MobileNav pageWrapId={"page-content"} outerContainerId={"App"}  auth={this.props.auth} />
 
        </Container>
      </header> 
    )
  }
}

export default withLocalize(Header);
