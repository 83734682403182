import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Translate } from "react-localize-redux";
import axios from "axios";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class ProfileEdit extends Component{
  constructor(props) {
    super(props); 
    this.state = {
      FirstName: '',
      LastName: '',
      DisplayName: '',
      Email: '',
      IsPublic: false,
      Language: '',
      BirthYear: 0,
      Gender: '',
      OptedIn: false,
      Iso2CountryCode: '',
      TestUser: false,
      Tags: '',
      AudioQuality: '',
      IsVerified: false,
      UserCentricsControllerID: ''
    }   
  }

  componentDidMount() {
    this.loadProfile();    
  }

  loadProfile = () => {
    axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile/`, { 
      headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
      }
    )
    .then(resp => {
      this.setState({ 
        FirstName: resp.data.FirstName,  
        LastName: resp.data.LastName,
        DisplayName: resp.data.DisplayName,
        Email: resp.data.PrimaryEmail,
        IsPublic: resp.data.IsPublic,
        Language: resp.data.Language,
        BirthYear: resp.data.BirthYear,
        Gender: resp.data.Gender,
        OptedIn: resp.data.OptedIn,
        AudioQuality: resp.data.AudioQuality,
        IsVerified: resp.data.IsVerified,
        UserCentricsControllerID: resp.data.UserCentricsControllerID
      }); 
    })
    .catch(err => {
      console.log(err);      
    })
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitHandler = e => {
    e.preventDefault();
    axios.patch(`${base_services_api}/users/me/details`,this.state,
     { 
       headers: { 
         'StoreId': storeId, 
         'Authorization': `Bearer ${this.props.auth.authData.access_token}`
       }        
     }
   )  
   .then(resp => {
    this.props.history.push("/profile");
   })
   .catch(err => {
      console.log(err);
    }) 
  }

  render() {
    const { FirstName, LastName, Email } = this.state;
    return (
        <div className="page-content bg-gradient" id="content">
          <div className="redeem-header">
            <section className="container">
              <Row>
                <Col>
                  <h1><Translate id="title.edit-profile" /></h1>
                </Col>
              </Row>
            </section>
          </div>
                 
          <section class="account">
            <div className="container">
              <form onSubmit={this.submitHandler}>
                <Row>
                  <Col sm="12" md="8" lg="8">
                    <div className="form-group">
                      <label><Translate id="content.first-name" /></label>
                      <Translate>
                        {({ translate }) => 
                        <input type="text" className="form-input" name="FirstName" value={FirstName} onChange={this.changeHandler} placeholder={translate("content.enter-firstname")} maxLength="50"/>}
                      </Translate>
                    </div>
                  </Col>
                </Row>  
                <Row>
                  <Col sm="12" md="8" lg="8">
                    <div className="form-group">
                      <label><Translate id="content.last-name" /></label>
                      <Translate>
                        {({ translate }) => 
                        <input type="text" className="form-input" name="LastName" value={LastName} onChange={this.changeHandler} placeholder={translate("content.enter-lastname")} maxLength="50"/>}
                      </Translate>
                    </div>
                  </Col>
                </Row>   
                <Row>
                  <Col sm="12" md="8" lg="8">
                    <div className="form-group">
                      <label><Translate id="content.email-address" /></label>
                      <Translate>
                        {({ translate }) => 
                        <input type="text" className="form-input" name="Email" value={Email} onChange={this.changeHandler} placeholder={translate("content.enter-email")} maxLength="50"/>}
                      </Translate>
                    </div>
                  </Col>
                </Row>                       
                <button className="btn-primary" type='submit' ><Translate id="button.submit" /></button>
              </form>   
            </div>
          </section>       
        </div>
   )
 }
}

export default withRouter(ProfileEdit);
