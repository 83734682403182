import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";
import PhoneButton from "./phonebutton";
import PhoneAuth from "./firebase-phoneauth";
import Loader from "../components/loader";

const storeId = process.env.REACT_APP_STOREID;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      errorMessage: "",
      isLoading: false,
      selectedValue: 'default',
      hideOptions: false,
      buttonClicked: false
    }

  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
      hideOption: true
    });
  }
  async loginResponse(response) {
    const { status } = response;

    if (status === 'PARTIALLY_AUTHENTICATED') {
      this.auth.authData = response.data;
      this.loginUser(this.auth, storeId);
    }
  };

  loginUser = (auth, storeId) => {
    const phone_number = parseInt(auth.authData.phone.number)
    const msisdn = `${auth.authData.phone.countryCode}${phone_number}`;
    const firebaseAccessToken = auth.authData.token;

    axios.get(`${process.env.REACT_APP_SERVICES_API_BASE}/application/grantUrl`, { headers: { 'StoreId': storeId } })
      .then(grantResponse => {
        const grantURL = grantResponse.data.grantURL;
        localStorage.setItem("grantURL", grantURL);

        axios.post(`${grantURL}`, `grant_type=msisdn&msisdn=${msisdn}&skip_decryption=true&code=${firebaseAccessToken}`, { headers: { 'StoreId': storeId, 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(resp => {
            const status = resp.status;
            
            if (status === 200) {
              this.setState({ isLoggedIn: true, isLoading: false });
              const accessToken = resp.data.access_token;
              const data = jwt_decode(accessToken);
              auth.authData.sub = data.sub;

              this.setState({ isLoading: true });
               
              auth.authData.access_token = accessToken;
              auth.authData.refresh_token = resp.data.refresh_token;
              auth.storeAuth();

              this.props.history.push("/profile");
              window.location.reload();                      

            }

          })
          .catch(err => {
            console.log(err);
            
            if (Object.keys(err).indexOf('response') === -1) {
              console.log(err);
              
              return;
            }

            const status = err.response.data.error;

            switch (status) {
              // Register the user
              case "604": 
                this.registerUser(auth, storeId);
                break;
              // Invalid phone number
              case "603":
                this.setState({
                  errorMessage: "Can't authenticate. Phone number not valid."
                })
                break;
              default: console.log(err);
            }

            this.setState({ isLoggedIn: false, isLoading: false });
            
          });
      })
  }

  registerUser = (auth, storeId) => {

    const registerData = {
      PhoneNumber: parseInt(auth.authData.phone.number),
      CountryCode: auth.authData.phone.countryCode,
      Token: auth.authData.token
    }

    axios.post(`${process.env.REACT_APP_SERVICES_API_BASE}/users/registerbymsisdn`, registerData, { headers: { 'StoreId': storeId } })
      .then(resp => {
        this.loginUser(auth, storeId);
      })
      .catch(err => {
        console.log(err);
      })
  }

  handleButtonClicked = () => {
    this.setState({ buttonClicked: true });
  }

  render() {
    this.auth = this.props.auth;
    let profileButton;

    if (this.auth.isAuthenticated()) {
      profileButton = (
        <Link to='/profile' onClick={this.onClick} className="nav-btn"><Translate id="button.profile" /></Link>
      );
    } else {
      if (!this.state.buttonClicked) {
        profileButton = (
          <Translate>
            {({ translate }) => <PhoneButton onButtonClicked={this.handleButtonClicked} btnClass="nav-btn" btnText={translate("button.login")} />}
          </Translate>
        );
      } else {
        profileButton = (
          <>
            <Translate>
              {({ translate }) => <PhoneButton onButtonClicked={this.handleButtonClicked} btnClass="nav-btn" btnText={translate("button.login")} />}
            </Translate>
            <PhoneAuth closeModal={() => { this.setState({ buttonClicked: false }) }} loginResponse={this.loginResponse.bind(this)} />
          </>
        );
      }
    }

    return (
      <div>
        {profileButton}

        {
          this.state.errorMessage !== '' &&
          <div className="status-error">
            {this.state.errorMessage}
          </div>
        }
        {
          this.state.isLoading &&
          <div className="loader">
            <div className="container">
              <Loader/>
            </div>
          </div>
        }

      </div>
    )
  }
}

const WithLogin = withRouter(Login);
export default withLocalize(WithLogin);