import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { Translate } from "react-localize-redux";
import axiosService from "../utils/axiosservice";

import copyIcon from "../assets/img/copy-icon.png";
import profileIcon from "../assets/img/profile-icon.png";

const base_invite_url = process.env.REACT_APP_INVITE_URL;

class Invite extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      invitationCodes: [],
      profile: [],
      sharedUsers: [],
      maxSharedUsers: [],
      acceptResult: ""
    } 
    this._isMounted = false;  
  }
  
  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadProfile(); 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
 
  loadSharedUsers = () => {
    axiosService.get(`/subscription/${this.state.profile.Subscriptions[0].SubscriptionId}/shared-by/`)
    .then(resp => {
      this.setState({ 
        sharedUsers: resp.data        
      });      
    })
    .catch(err => {
      this.setState({ 
        sharedUsers: []      
      });   
    })
  }

  loadProfile = () => {
    axiosService.get(`/users/${this.props.auth.sub}/profile`)
    .then(resp => {
      this._isMounted && this.setState({ 
        profile: resp.data,
        maxSharedUsers: resp.data.Subscriptions[0].PackageCost.MaxSharedUsers      
      });  

      if (this.state.profile.Subscriptions[0].PackageCost.AllowShare === true) {
        this.loadInvitationCodes();
        this.loadSharedUsers();   
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  
  loadInvitationCodes = () => {
    axiosService.get(`/subscription/${this.state.profile.Subscriptions[0].SubscriptionId}/invitation-codes/`)
    .then(resp => {
        this.setState({ 
        invitationCodes: resp.data        
      })
    })
    .catch(err => {
      console.log(err);      
    })
  }

  onInviteClick = () => {
    axiosService.post(`/subscription/${this.state.profile.Subscriptions[0].SubscriptionId}/invitation-code`,null)  
    .then(resp => {
      this.loadInvitationCodes();         
    })
    .catch(err => {
       console.log(err);   
    })  
  }

  onAcceptInviteClick = () => {
    let sharedCode = sessionStorage.getItem('sharedCode');
    axiosService.post(`/subscription/invitations/${sharedCode}`,null)  
   .then(resp => {
      sessionStorage.removeItem('sharedCode');
      this.setState({ 
        acceptResult: "You are now part of the family plan"      
      });          
   })
   .catch(err => {
      const status = err;

      switch (status) {
        case "400":
          this.setState({ 
            acceptResult: "Sorry, you are already on an active subscription. Cancel your subscription to join this plan."      
          });  
          break;
        default: 
        this.setState({ 
          acceptResult: "Something went wrong. This invite may have already been used or expired."      
        }); 
      }
    }) 
}

  onRemoveClick = (userId) => {
    if (!window.confirm("Are you sure you want to remove this user from your plan?")) 
        return;

    axiosService.delete(`/subscription/${this.state.profile.Subscriptions[0].SubscriptionId}/shared-users/${userId}`)  
    .then(resp => {
      this.loadInvitationCodes();  
      this.loadSharedUsers();
    })
    .catch(err => {
      console.log(err);   
    })    
  }

  async loadSubscriptions() {
    try {
      const subscriptionsData = await this.props.auth.loadSubscriptions();
      this.setState({ subscriptions: subscriptionsData.data })
    }
    catch (err) {
      this.setState({ subscriptions: [] })
    }
  }  

  
  render() {
    const authData = this.props.auth;
    let familySection;
    let acceptInviteSection;
    let listInvitedUsers;
    let listInvitationCodes;
    let btnInvite;
    
    if (typeof this.state.sharedUsers !== 'undefined') {
       if(this.state.sharedUsers.length > 0){
         listInvitedUsers = this.state.sharedUsers.map((shuser) => {
         return <Fragment>
            <Row key={shuser.UserId} className="align-items-center manage-user">
               <Col sm="12">
                <span className="manage-icon"><img src={profileIcon} alt="User" /></span>
                <span className="align-items-center">
                  {shuser.FirstName ? shuser.FirstName : "+" + authData.phone.countryCode + parseInt(authData.phone.number)}
                </span>
                <button className="btn-remove float-right" onClick={()=> this.onRemoveClick(shuser.UserId)}><Translate id="button.remove" /></button>
               </Col>
          </Row>
        </Fragment>
      });    
    } 
  }
    
    if (typeof this.state.invitationCodes.Codes !== 'undefined') {
      if(this.state.invitationCodes.Remaining > 0){
        btnInvite = <Col sm="2">
                     <button className="btn-primary" onClick={this.onInviteClick}><Translate id="button.invite" /></button>
                    </Col>
      }
        
      if(this.state.invitationCodes.Codes.length > 0){
        listInvitationCodes = this.state.invitationCodes.Codes.map((code) => {
          return <div className="invite-code" key={code}>
            <Row>
              <Col sm="12"><strong><Translate id="content.send-the-below-link" /></strong></Col>
            </Row>
            <Row className="align-items-center invite-link">
              <Col sm="10" xs="10">
                <input type="text" value={`${base_invite_url}?code=${code}`} disabled></input>
              </Col>
              <Col sm="2" xs="2">
                 <button className="btn-copy float-right" onClick={() => {navigator.clipboard.writeText(base_invite_url + "?code=" + code)}}><img src={copyIcon} alt="Copy" /></button>
              </Col>
            </Row>
          </div>   
        });            
      }
    }    
    
    if (typeof this.state.profile.Subscriptions !== 'undefined') {
      // eslint-disable-next-line
      if(this.state.profile.Subscriptions[0].OwnerId == authData.sub && this.state.profile.Subscriptions[0].PackageCost.AllowShare === true) {
        familySection = <Fragment>
          <h2 className="u-no-margin-bottom"><Translate id="content.welcome" /> {this.state.profile.FirstName}</h2>
          <p className="u-no-margin-top"><Translate id="content.manage-your-family-plan" /></p>
          <Row>
            <Col sm="12" md="12" lg="12">
              <div className="invite">
                <div className="invite-details">
                  <h3 className="u-no-margin-bottom u-no-margin-top"><Translate id="content.invite-family-members" /></h3>
                  <Row>
                    <Col sm="10">
                      <p><Translate id="content.generate-invitation-link" /> {this.state.maxSharedUsers} <Translate id="content.family-members" /></p>
                    </Col>
                    <Col sm="2" className="justify-content-center align-self-center float-right">
                      {btnInvite}
                    </Col>
                  </Row>
                </div>
                <Row className="invite-list">
                  {listInvitationCodes}
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="invite-fineprint"><Translate id="content.invitation-link-fineprint" /></div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div className="manage">
                <h3 className="u-no-margin-bottom"><Translate id="content.manage-your-family" /></h3>
                {listInvitedUsers}
                <small className="invite-remaining"><Translate id="content.you-can-invite" /> {this.state.invitationCodes.Remaining} <Translate id="content.more-family-members" /></small>
              </div>
            </Col>
          </Row>
        </Fragment>
      }
    }

    if(sessionStorage.getItem('sharedCode') != null){
    acceptInviteSection = <div className="invite invite-details">
        <Row>
          <Col xs="8">
            <h3><Translate id="title.join-premium" /></h3>
          </Col>
          <Col xs="4">
            <div className="invite-fineprint">
              <button className="btn-primary" onClick={this.onAcceptInviteClick}><Translate id="button.accept-invite" /></button>              
            </div>
          </Col>
        </Row>
      </div>      
    }

    return (  
         <Fragment>      
           {familySection}   
           {acceptInviteSection}
           <div>
              <p>{this.state.acceptResult}</p>
           </div>
         </Fragment>        
      )      
  }
}

export default Invite;