import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Translate } from "react-localize-redux";

class Failed extends Component {
  
  render() {
    return (
      <div className="page-content bg-gradient" id="content">
        <section>
          <Container>
            <Row>
              <Col>
                <div className="u-text-center">
                  <h1><Translate id="title.payment-cancelled" /></h1>
                  <p className="u-text-white"><Translate id="content.issues-with-payment" /></p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default withRouter(Failed);
