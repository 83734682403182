// List of OTP countries. Remove any that are not needed.

const countries = () => { 
  return [
    {
        "name": "United States of America",
        "dial_code": "+1",
        "code": "US"
    },
    {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU"
    }
  ]
};
export default countries;