import React, { Component, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { Container, Row, Col } from "react-bootstrap";

class Success extends Component {
  
  render() {
    const auth = this.props.auth;
    let success;

    if (!auth.isAuthenticated()) {
      success = <Redirect to="/" />
    } else {
      success = <div className="page-content bg-gradient" id="content">
        <section>
          <Container>
            <Row>
              <Col>
                <div className="u-text-center">
                  <h1><Translate id="title.subscription-active"/></h1>
                  <p className="u-text-white"><Translate id="content.premium-now-active"/></p>
                  <div className="buttons u-margin-top">
                    <Link to="/profile" className="btn-primary"><Translate id="button.go-to-profile"/></Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    }

    return (
      <Fragment>
        {success}
      </Fragment>
    );
  }
}

export default withRouter(Success);
